import * as React from "react";
import { Link } from "gatsby";

import {
  Box,
  Heading,
  List,
  ListItem,
  ListIcon,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { CheckCircleIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const Who = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      <Box mt="100px">
        <Link to="/">
          <Text
            mt="40px"
            mb="20px"
            size="xl"
            fontWeight="extrabold"
            maxW="2xl"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
            color={colorMode === "light" ? "gray.500" : "whiteAlpha.900"}
            _hover={
              colorMode === "light"
                ? { color: "#333" }
                : { color: "whiteAlpha.700" }
            }
          >
            <ChevronLeftIcon />
            Retour
          </Text>
        </Link>
        <Text
          fontSize={{ base: "44px", md: "68px" }}
          fontWeight="extrabold"
          lineHeight="1.1"
          bgGradient="linear(to-l, #CF55AA , #121FCF)"
          bgClip="text"
        >
          Qui peut se transformer en une Product First Company ?
        </Text>

        <Text fontSize="xl" my="40px">
          PARSK accompagne des Grandes Entreprises, des ETI et TPE, des PME et
          des startups, dans tous les secteurs.{" "}
          <b>
            Des médias, des marques de retail et e-commerce, des acteurs de
            l'industrie culturelle ou encore des institutions du service
            publique
          </b>{" "}
          se sont appuyés sur la méthodologie PARSK pour aligner leurs équipes
          sur leur stratégie globale, et devenir des Product First Company.
          <br />
          <br /> Quel que soit le secteur et la taille de l'entreprise, les
          forces vives d'une même structure -dirigeants, top managers, équipes-
          sont ainsi capables de se mettre en mouvement autour du{" "}
          <b>
            Produit, propice à quatre grandes vertus au sein des organisations
          </b>
          .
        </Text>

        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            mb="20px"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            Un cap à suivre
          </Heading>
          <Text fontSize="xl" my="4">
            Cette méthodologie permet de fournir un cap, une direction vers
            laquelle tendre en toutes circonstances. Elle donne aux dirigeants
            les clés pour opérationnaliser leur stratégie. Le framework ainsi
            constitué servira de programme{" "}
            <b>
              auprès de l'interne pour véhiculer les éléments de discours et de
              conviction afin d'embarquer les équipes, et auprès de l'externe
              pour communiquer sur la stratégie et les actions à déployer
            </b>
            .
          </Text>
        </Box>

        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            La question du sens
          </Heading>
          <Text fontSize="xl" my="4">
            Les salariés d’aujourd’hui ont besoin de sens dans leur travail, ils
            veulent avoir la sensation d’être impliqués dans les décisions
            prises par leur entreprise, d’être associés à la réalisation de
            grandes missions. La méthodologie développée par PARSK permet
            justement d’
            <b>
              associer tous les salariés de l’entreprise, de les rassembler
              autour d’une même stratégie clairement définie et qui a du sens,
              être impliqués dans la recherche de solutions
            </b>
            .
          </Text>
        </Box>
        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            Le gain de productivité
          </Heading>
          <Text fontSize="xl" my="4">
            En permettant à chaque employé de se consacrer à la réalisation
            d’objectifs précis, servant une stratégie globale, PARSK donne à
            l'entreprise{" "}
            <b>
              les moyens de déployer sa stratégie le plus efficacement
              possible. Tout le monde va dans la même direction
            </b>{" "}
            en se partageant intelligemment le travail et en s’entraidant. Il
            n’y a pas d’énergie dépensée sur des tâches inutiles ou
            contre-productives.
          </Text>
        </Box>
        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            La force du collectif
          </Heading>
          <Text fontSize="xl" my="4">
            PARSK permet de{" "}
            <b>
              casser les silos de l'entreprise en partageant une stratégie, une
              vision Produit et des objectifs communs, qui vont permettre une
              démarche synergique
            </b>
            . Un objectif atteint par un salarié va s’ajouter à ceux de ses
            collaborateurs et c’est la somme de tous ces résultats individuels
            qui va permettre d’accomplir une mission commune.
          </Text>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Who;
